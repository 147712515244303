import { createRouter, createWebHistory } from 'vue-router'

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', redirect: '/login' },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView.vue')
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('../views/ForgotPassword.vue')
    },
    {
      path: '/reset-password/:uuid',
      name: 'reset-password-uuid',
      component: () => import('../views/RecoveryPassword.vue'), 
      props: true
    },
    {
      path: '/confirm-email/:uuid',
      name: 'confirm-email-uuid',
      component: () => import('../views/ConfirmEmail.vue'), 
      props: true
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('../views/Registration.vue')
    } 
  ]
})

export default router
